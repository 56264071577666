<template>
  <div class="home">
    <div class="home-container">
      <div class="bar-wrap" v-for="(item1, index1) in navbar" :key="'wrap' + index1">
        <div class="bar-title">{{ item1.title }}</div>
        <div class="bar-list">
          <div class="bar-item" v-for="(item, index) in item1.bar" :key="index">
            <div class="icon-wrap" @click="barEvent(item)">
              <img :src="item.icon" alt="" />
              <span class="badge" v-if="item.badge">{{ item.badge }}</span>
            </div>
            <p class="label" @click="barEvent(item)">{{ item.label }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import repairMixin from '@views/shareAndDispatch/device/deviceRepair/reapirList/repairMixin'
export default {
  name: '',
  mixins:[repairMixin],
  data() {
    return {
      navbar: [
        {
          title: '报障维修',
          key: 'reportCount1',
          bar: [
            {
              label: '维修响应',
              icon: require('@assets/images/deviceView/deviceView-home-icon-11.png'),
              badge: 0,
              path: '/deviceRepair/reapirList/repairResponse',
              key: 'count1'
            },
            {
              label: '催单响应',
              icon: require('@assets/images/deviceView/deviceView-home-icon-12.png'),
              badge: 0,
              path: '/deviceRepair/reapirList/remindResponse',
              key: 'count2'
            },
            {
              label: '维修审核',
              icon: require('@assets/images/deviceView/deviceView-home-icon-14.png'),
              badge: 0,
              path: '/deviceRepair/reapirList/repairedExamine',
              key: 'count3'
            },
            {
              label: '维修处理',
              icon: require('@assets/images/deviceView/deviceView-home-icon-15.png'),
              badge: 0,
              path: '/deviceRepair/reapirList/repairedHandle',
              key: 'count4'
            },
            {
              label: '维修考评',
              icon: require('@assets/images/deviceView/deviceView-home-icon-16.png'),
              badge: 0,
              key: 'count5',
              path: '/deviceRepair/reapirList/repairEvaluateList'
            },
            {
              label: '报修',
              icon: require('@assets/images/deviceView/deviceView-home-icon-13.png'),
              badge: 0,
              path: '/deviceBorrow/deviceRepair',
              query:{viewType:'device'},
            }
          ]
        },
      ]
    };
  },
  created() {},
  mounted() {
    this.updateData();
    this.addLocalStorageEventByKey('allDeviceUpdate', this.updateData)
  },
  beforeDestroy() {
    this.removeLocalStorageEvent(this.updateData)
  },
  methods: {
    searchEvent:function() {
      this.reportCount1();
    },
    updateData:function() {
      this.reportCount1();
    },
    barEvent(item) {
      if (!item.path) return;
      let query = {};
      if (this.$valueIsExist(item, 'query')) {
        query = item['query']
      }

      this.$push(item.path, query);
    },
    reportCount1() {
      this.$api.deviceOrg
          .reportCount1()
          .then(res => {
            this.navbar[0].bar.map(item => {
              if (item.key) {
                item.badge = res.data[item.key];
              }
              return item;
            });
          })
          .catch(e => {});
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
.home {
  width: 100%;
  height: 100%;
  padding: 10px 0px;
  .home-container {
    width: 100%;
    height: 100%;
    padding: 0 14px;
    overflow-y: auto;
    .bar-wrap {
      background: #ffffff;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 12px 18px;
      &:not(:first-child) {
        margin-top: 10px;
      }
      .bar-title {
        font-size: 14px;
        color: #333;
      }
      .bar-list {
        display: flex;
        flex-wrap: wrap;
        .bar-item {
          width: 25%;
          margin-top: 44px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon-wrap {
            width: 28px;
            height: 28px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
            }
            .badge {
              position: absolute;
              left: 20px;
              top: -8px;
              background: #fb6434;
              padding: 2px 4px;
              line-height: 12px;
              min-width: 16px;
              font-size: 12px;
              text-align: center;
              border-radius: 14px;
              color: #fff;
            }
          }
          .label {
            font-size: 14px;
            color: #333;
            padding-top: 16px;
          }
        }
      }
    }
  }
}
</style>
